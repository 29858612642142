import React from "react";
import Breadcrumb from "../../../common/breadcrumb.component";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button } from "reactstrap";
import API from "../../../../request/api";
import SweetAlert from "react-bootstrap-sweetalert";

import GridLayout from "react-grid-layout";
import "../../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../../node_modules/react-resizable/css/styles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Shake } from "reshake";
//json file

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      limit: 15,
      allItems: 0,
      currentPage: 1,
      data: [],
      arrange_layout: false,
      btn_style: {
        marginRight: 10,
      },
      action_margin_right: {
        marginRight: 0,
      },
      level: 2, // 1 country, 2 topic, 3 sub
      temp_sub: [],
      temp_category: null,
    };
  }

  hideAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  UNSAFE_componentWillMount() {
    this.requestData();
  }

  async requestData() {
    this.setState({
      data: [],
    });
    let result = await API.getAllCategory(
      this.state.data.length,
      this.state.level
    );
    if (result.result) {
      // result.data.category.splice(0, 0, {
      //   id: 0,
      //   name_en: "ALL COUNTRY",
      //   notClickable: true,
      //   active_image_url:
      //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpvaXDgTSps17diLlSysTLOOs_C4Ph7Rvbgg&usqp=CAU",
      //   sub_category: result.data.category.map((c) => c),
      // });


      this.setState({
        data: result.data.category,
        allItems: result.data.all,
        limit: result.data.limit,
      });
    } else {
      this.toastFail(result.message);
    }
  }

  toastSuccess() {
    toast.success("Action Successful !");
  }

  toastFail(text) {
    toast.error(text);
  }

  onUpdateOrder(d) {
    API.updateCategoryOrder(this.state.temp_sub);
    console.log(this.state.temp_sub);
  }

  onEndDrag(item) {
    this.state.temp_sub = [];
    console.log(item);
    let order_number = 1;
    for (let y = 0; y < 100; y++) {
      for (let x = 0; x < 10; x++) {
        item.forEach((i) => {
          if (i.y == y && i.x == x) {
            this.state.temp_sub.push({
              id: i.i,
              order_number: order_number++,
            });
          }
        });
      }
    }
  }

  arrangeLayout(d) {
    let data = [];
    d.sub_category.forEach((sub, i) => {
      data.push(
        <div
          style={{ textAlign: "center" }}
          key={sub.id}
          data-grid={{ x: (i % 5) * 2, y: parseInt(i / 5), w: 2, h: 1 }}
        >
          <Shake
            h={5}
            v={5}
            r={5}
            int={20}
            active={d.arrange_layout || false}
            fixed={d.arrange_layout}
            onClick={() => {
              if (!d.arrange_layout) {
                this.props.history.push(
                  `/category/topic/edit?category_id=${sub.id}`
                );
              }
            }}
          >
            <img
              src={sub.active_image_url}
              style={{ opacity: sub.is_deleted ? 0.2 : 1 }}
              className="img-100 "
            />
          </Shake>

          <p style={{ color: sub.is_deleted ? "red" : "black" }}>
            {sub.name_en}
          </p>
        </div>
      );
    });
    return (
      <GridLayout
        margin={[30, 30]}
        isBounded={true}
        isDraggable={d.arrange_layout || false}
        isResizable={false}
        compactType="horizontal"
        className="layout"
        cols={10}
        rowHeight={100}
        width={1000}
        preventCollision={false}
        onDragStop={(item) => this.onEndDrag(item)}
      >
        {data.map((d) => d)}
      </GridLayout>
    );
  }

  tableLayout(props, paginations, totalPage) {
    return (
      <div>
        <BootstrapTable
          {...props.baseProps}
          // pagination={paginationFactory(options)}
        />
        <div
          className="card-body digits"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {/* <i
            className="icon-angle-left"
            onClick={() => {
              if (this.state.currentPage != 1) {
                this.onPageChanged(this.state.currentPage - 1);
              }
            }}
            style={{ marginRight: 5 }}
          ></i>
          {paginations}
          <i
            onClick={() => {
              if (this.state.currentPage != totalPage) {
                this.onPageChanged(this.state.currentPage + 1);
              }
            }}
            className="icon-angle-right"
          ></i> */}
        </div>
      </div>
    );
  }

  sweetAlert() {
    return (
      <SweetAlert
        showCancel
        confirmBtnText={
          this.state.temp_category.is_deleted ? "Put Back" : "Delete"
        }
        confirmBtnBsStyle={
          this.state.temp_category.is_deleted ? "info" : "danger"
        }
        type={this.state.temp_category.is_deleted ? "info" : "danger"}
        title="Are you sure?"
        onCancel={this.hideAlert}
        onConfirm={this.onConfirmBlock}
      >
        {this.state.temp_category.is_delete
          ? "This will put back the feed"
          : "This will remove the Category"}
      </SweetAlert>
    );
  }

  onConfirmBlock = async () => {
    this.hideAlert();

    console.log(this.state.temp_category);

    let form = new FormData();
    form.append("is_deleted", this.state.temp_category.is_deleted ? 0 : 1);
    form.append("category_id", this.state.temp_category.id);

    let result = await API.updateCategory(form);

    if (result.result) {
      this.toastSuccess();
      this.requestData();
    } else {
      this.toastFail(result.message);
    }
  };

  onComfirtBlock = () => {
    this.hideAlert();
    this.toastSuccess();
  };

  onPageChanged(page) {
    this.requestData();
    this.setState({ currentPage: page });
  }

  body(d) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mb-0">
              <div>
                <span style={{ right: 150, position: "absolute", top: 10 }}>
                  <Button
                    onClick={() => {
                      if (d.arrange_layout) {
                        this.onUpdateOrder(d);
                        this.toastSuccess();
                      }
                      d.arrange_layout = !d.arrange_layout;
                      this.setState({});
                    }}
                    color="primary"
                  >
                    {d.arrange_layout ? "Save" : "Arrange Order"}
                  </Button>
                </span>

                {d.arrange_layout && (
                  <span style={{ right: 30, position: "absolute", top: 10 }}>
                    <Button
                      onClick={() => {
                        d.arrange_layout = !d.arrange_layout;
                        this.setState({});
                      }}
                      color="secondary"
                    >
                      Cancel
                    </Button>
                  </span>
                )}

                <div style={{ textAlign: "center", marginTop: 10 }}>
                  <img
                    onClick={() => {
                      if (!d.arrange_layout && !d.notClickable) {
                        this.props.history.push(
                          `/category/topic/edit?category_id=${d.id}`
                        );
                      }
                    }}
                    src={d.active_image_url}
                    className="img-100 "
                  />

                  <p>{d.name_en}</p>
                </div>

                {this.arrangeLayout(d)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onCheckboxClicked = async (value, item, type) => {
    let form = new FormData();
    form.append(type, value ? 1 : 0);
    form.append("category_id", item.id);

    console.log(value, item, type);

    let result = await API.updateCategory(form);

    if (result.result) {
      this.toastSuccess();
      // this.requestData();
    } else {
      this.toastFail(result.message);
    }
  };

  render() {
    let totalPage = Math.ceil(this.state.allItems / this.state.limit);
    let paginations = [];
    for (let i = 1; i <= totalPage; i++) {
      paginations.push(
        <a
          key={i}
          href="#"
          onClick={() => this.onPageChanged(i)}
          className={
            "badge badge-" + (this.state.currentPage == i ? "info" : "light")
          }
        >
          {i}
        </a>
      );
    }
    var data = [];
    this.state.data.forEach((d) => {
      if (d.id != 0) {
        console.log(d)
        d.sub_category.forEach ((sub, index) => {
          sub.number = this.state.currentPage * index + 1;
          data.push(sub);
        })
        
      }
    });
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "number",
        text: "N",
      },
      {
        dataField: "name_en",
        text: "Name En",
      },
      {
        dataField: "name_kh",
        text: "Name Kh",
      },
      {
        dataField: "sub_of",
        text: "Subcategory Of",
      },
      
      {
        dataField: "active",
        text: "Online Shop",
        formatter: (rowContent, row) => {
          return (
            <input
              type="checkbox"
              defaultChecked={row.online_shop_availability}
              onChange={(event) =>
                this.onCheckboxClicked(
                  event.target.checked,
                  row,
                  "online_shop_availability"
                )
              }
              value={row.online_shop_availability}
            />
          );
        },
      },
      {
        dataField: "active",
        text: "Online Class",
        formatter: (rowContent, row) => {
          return (
            <input
              type="checkbox"
              defaultChecked={row.online_class_availability}
              onChange={(event) =>
                this.onCheckboxClicked(
                  event.target.checked,
                  row,
                  "online_class_availability"
                )
              }
              value={row.online_class_availability}
            />
          );
        },
      },
      {
        dataField: "active",
        text: "Music",
        formatter: (rowContent, row) => {
          return (
            <input
              type="checkbox"
              defaultChecked={row.music_availability}
              onChange={(event) =>
                this.onCheckboxClicked(
                  event.target.checked,
                  row,
                  "music_availability"
                )
              }
              value={row.music_availability}
            />
          );
        },
      },
      {
        dataField: "is_deleted",
        text: "Status",
        formatter: (rowContent, row) => {
          let status;
          if (!row.is_deleted) {
            status = <span className={"badge badge-success"}>Verified</span>;
          } else {
            status = <span className={"badge badge-danger"}>Removed</span>;
          }
          return status;
        },
      },
      {
        dataField: "action",
        text: "Action",
        formatter: (rowContent, row) => {
          return (
            <div style={this.state.action_margin_right}>
              <Link to={`/category/topic/view?category_id=${row.id}`}>
                <Button
                  size="sm"
                  color="info"
                  style={this.state.btn_style}
                  className="btn-square active"
                >
                  View
                </Button>
              </Link>
              <Link to={`/category/topic/edit?category_id=${row.id}`}>
                <Button
                  size="sm"
                  color="warning"
                  style={this.state.btn_style}
                  className="btn-square active"
                >
                  Edit
                </Button>
              </Link>
              <Button
                size="sm"
                color={row.is_deleted ? "primary" : "danger"}
                onClick={() =>
                  this.setState({ showAlert: true, temp_category: row })
                }
                style={this.state.btn_style}
                className="btn-square active"
              >
                {row.is_deleted ? "Put Back" : "Delete"}
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <Breadcrumb title="Index" parent="Category" />
        {this.state.showAlert && this.sweetAlert()}
        <ToastContainer />

        {/* LIST VIEW BODY */}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <ToolkitProvider
                    keyField="name"
                    data={data}
                    columns={columns}
                    // search
                  >
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <Link
                          to={"/category/category/new"}
                          style={{ position: "absolute", right: 30 }}
                        >
                          <Button
                            color="info"
                            className="btn-pill btn-info btn-air-info"
                          >
                            New
                          </Button>
                        </Link>

                        <BootstrapTable
                          {...props.baseProps}
                          // pagination={paginationFactory(options)}
                        />
                        <div
                          className="card-body digits"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <i
                            className="icon-angle-left"
                            onClick={() => {
                              if (this.state.currentPage != 1) {
                                this.onPageChanged(this.state.currentPage - 1);
                              }
                            }}
                            style={{ marginRight: 5 }}
                          ></i>
                          {paginations}
                          <i
                            onClick={() => {
                              if (this.state.currentPage != totalPage) {
                                this.onPageChanged(this.state.currentPage + 1);
                              }
                            }}
                            className="icon-angle-right"
                          ></i>
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        {this.state.data.map((d) => {
          return (
            <div>
              {this.body(d)}

              <br />
            </div>
          );
        })}
      </div>
    );
  }
}

export default Layout;
